<template>
    <div class="p-4 h-100">
        <div class="d-flex">
            <div class="input-group-prepend">
                <span 
                    class="input-group-text brl" 
                    id="basic-addon1" 
                    style="
                        font-size: 2rem;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                    "
                >$</span>
            </div>
            <currency-input
                :value="amount"
                @change="amount = $event"
                class=""
                style="
                    font-size: 2rem;
                    text-align: center;
                    border-top-right-radius: 15px; 
                    border-bottom-right-radius: 15px; outline: none;
                    border: 1px solid #ced4da;
                    background-color: #e9ecef;
                    color: #7BAA64;
                "
                :options="{
                    currency: 'USD',
                    currencyDisplay: 'hidden',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    useGrouping: true,
                    valueRange: { min: 0, max: 100000 },
                }"
            />

            <div @click="amount = availableBalance/100" class="p-2 ml-2" style="cursor: pointer; border: 1px solid black; border-radius: 15px; height: 60px; line-height: 42px; vertical-align: center; font-size: 2rem;"> MAX</div>
        </div>

        <div class="mt-1 text-center">Available balance: ${{ (availableBalance / 100).toFixed(2) }}</div>

        
        <!-- Payment specific section -->
        <div class="mt-3">
            <div class="form-row">
                <div class="col">
                    <div class="form-group d-flex">


                        <div class="input-group-prepend dropdown">
                            <a
                                href="#"
                                class="input-group-text brl dropdown-toggle"
                                id="gameDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                style="
                                    font-size: 1.2rem;
                                    border-top-right-radius: 0px;
                                    border-bottom-right-radius: 0px;
                                    border-top-left-radius: 15px;
                                    border-bottom-left-radius: 15px;
                                    background-color: #e3e3e3;
                                    height: 38px;
                                    cursor: pointer;
                                    padding: 0 20px;
                                "
                            >
                                {{ selectedCurrency.short }}
                                <!-- <font-awesome-icon class="ml-2" icon="caret-down"  style="color: #495057;"/> -->
                            </a>
                            <div class="dropdown-menu" aria-labelledby="gameDropdown">
                                <a v-for="currency in currencies" :key="currency.id" class="dropdown-item" href="#" @click="selectedCurrencyId = currency.id">{{ currency.short }}</a>
                            </div>
                        </div>
                        <div class="input-group-prepend">
                            <span 
                                class="input-group-text brl" 
                                id="basic-addon1" 
                                style="
                                    height: 38px;
                                    border-top-right-radius: 0px;
                                    border-bottom-right-radius: 0px;
                                "
                            ><icon-email class="iconcustom" /></span>
                        </div>
                        <input type="email" class="form-control" id="email" placeholder="Coinbase Email Address" v-model="email">
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <div class="form-group d-flex">

                        <div class="input-group-prepend">
                            <span 
                                class="input-group-text brl" 
                                id="basic-addon1" 
                                style="
                                    height: 38px;
                                    border-top-right-radius: 0px;
                                    border-bottom-right-radius: 0px;
                                "
                            >Fee</span>
                        </div>
                        <input type="text" class="form-control" disabled v-model="transactionFee">
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center h3 mt-3">
            <b>Total:</b> ${{this.netAmount}}
        </div>

        <div class="text-center mt-2">
            <div class="btn btn-orange" @click="withdraw">Withdraw</div>
        
        </div>
        <div class="mt-1 text-center" style="font-size: 0.8rem;">All transactions are processed in USD. User is responsible for transaction fees.</div>
    </div>
</template>

<script>
import api from "@/api.js";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            currencies: [
                { id: "BTC", symbol: "₿", short: "BTC", property: "btcAddress" },
                // { id: "ETH", symbol: "Ð", short: "ETH", property: "ethAddress" },
                // { id: "USDT", symbol: "$", short: "USDT", property: "usdtAddress" },
            ],
            selectedCurrencyId: "BTC",
            email: "",
            availableBalance: 0,
            amount: 0,
        }
    },
    async mounted() {
        this.email = this.user.email || ""
        this.amount = this.availableToWithdraw || 0;
        this.availableBalance = this.user.salesBalance || 0;

        if (this.user.payment_info && this.user.payment_info.coinbase) {
            this.email = this.user.payment_info.coinbase.email || this.email;
        }
    },
    computed: {        
        ...mapGetters({ 'user': 'auth/user'  }),
        transactionFee() {
            return "$" + (this.amount * 0).toFixed(2) + ' (0%)'
        },
        netAmount() {
            return (this.amount * 1).toFixed(2);
        },
        selectedCurrency() {
            return this.currencies.find(x => x.id == this.selectedCurrencyId);
        },
        test() { return this.email; }
    },
    methods: {
        async withdraw() {
            if (!this.email) {
                return Swal.fire('Error', 'Please enter your Coinbase email', 'error');
            }

            if (isNaN(this.amount) || this.amount < 1) return Swal.fire('Error', 'Please enter an amount greater than $1', 'error');
            let { dismiss } = await Swal.fire({
                title: "Are you sure?",
                text: `Are you sure you want to withdraw $${this.amount} to the following email: ${this.email} via Coinbase? This action is not reversible`,
                showCancelButton: true,
                showCloseButton: false,
                showConfirmButton: true,
            });
            
            if (dismiss) return console.log(`Dismissed`);

            Swal.showLoading();
            this.$emit('withdraw', {
                amount: this.amount,
                account: this.email,   
                currencyId: this.selectedCurrencyId,   
            });
        }
    }


}
</script>

<style lang="scss" scoped>

.material-design-icon.iconcustom {
  height: 20px;
  width: 25px;
  /* padding: */
}
.material-design-icon.iconcustom > .material-design-icon__svg {
  height: 1.5em;
  width: 1.5em;
}
</style>